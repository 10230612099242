<template>
  <div>
    <div class="form-wrapper">
      <h4 class="font-weight-bolder">
        {{ RFQ_SENT_BOTTOM_TITLE_ATTACHMENT }}
      </h4>
      <div>
        <b-table
          :items="attachments"
          :fields="uploadAttachmentTableCol"
          class="upload-attachment-table"
          :show-empty="true"
          :empty-text="'None found'"
        >
          <template #cell(action)="data">
            <div
              class="d-flex"
              style="gap: 8px"
            >
              <a @click="showAttachment(data.item)">
                <feather-icon
                  icon="LEyeIcon"
                  size="28"
                  style="padding: 5px"
                  class="cursor-pointer border-dotted feather-eye-icon"
                />
              </a>
            </div>
          </template>
          <template #cell(name)="{ item }">
            <div
              class="name-cell"
              style="width: 170px"
            >
              {{ item.name }}
            </div>
          </template>
          <template #cell(date)="{ item }">
            {{ mapDate(item.created_at) }}
          </template>
          <template #cell(type)="{ item }">
            {{ item.mime_type }}
          </template>
          <template #cell(category)="{ item }">
            {{ item.attachment_category }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  VBTooltip,
  BTable,
} from 'bootstrap-vue'
import { mapDate } from '@core/utils/utils'
import config from '../config'

export default {
  name: 'Attachment',
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showAttachment(item) {
      if (item.id) {
        window.open(item.url, '_blank')
      } else {
        window.open(
          (window.URL || window.webkitURL).createObjectURL(item.file),
          '_blank',
        )
      }
    },
  },
  setup() {
    const { uploadAttachmentTableCol, RFQ_SENT_BOTTOM_TITLE_ATTACHMENT } = config()

    return {
      mapDate,
      uploadAttachmentTableCol,
      RFQ_SENT_BOTTOM_TITLE_ATTACHMENT,
    }
  },
}
</script>
<style lang="scss">
.upload-attachment-table {
    tbody tr td {
      padding: 5px 5px 5px 20px
    }

  & .b-table-empty-row {
    td {
      padding: 5px !important;

      div {
        margin: 0 !important;
      }
    }
  }
}
</style>
