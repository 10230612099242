export default {
  order: {},
  orders: [],
  orderForm: {},
  orderPageTitle: 'Rental Order List',
  product: {},
  inventoryPermanentTransferFormByInventoryQty: {},
  transferOrderScheduledInformation: {},
  inventoryPermanentTransferFormByAsset: {},
  quoteTransferOrder: {},
}
