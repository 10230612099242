<template>
  <div
    class="d-flex align-items-center my-2"
    style="gap: 8px"
  >
    <div
      v-for="(_, index) in arr"
      :key="index"
      class="d-flex align-items-center"
      style="gap: 8px"
    >
      <div
        class="d-flex align-items-center"
        style="gap: 8px"
      >
        <b-skeleton
          width="26px"
          height="26px"
          class="mb-0"
        />
        <b-skeleton
          width="190px"
          height="26px"
          class="mb-0"
        />
      </div>
      <feather-icon
        v-if="index !== arr.length - 1"
        icon="LPolygonIconFill"
        size="18"
      />
    </div>
  </div>
</template>

<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
  name: 'QuoteStatusBarSkeleton',
  components: {
    BSkeleton,
  },
  data() {
    return {
      arr: Array(3),
    }
  },
}
</script>
