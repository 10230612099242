<template>
  <div> <slot /> </div>
</template>

<script>
import Vue from 'vue'
import VueHtmlToPaper from '../vue-html-to-papers'

export default {
  name: 'Printer',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    Vue.use(VueHtmlToPaper, this.customOptions)
  },
  setup(props) {
    const customOptions = {
      ...{
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          'https://unpkg.com/kidlat-css/css/kidlat.css',
          `${document.location.origin}/public-styles.css`,
        ],
        timeout: 1000,
        autoClose: true,
        windowTitle: '',
      },
      ...props.options,
    }
    return {
      customOptions,
    }
  },
}
</script>
