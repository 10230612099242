import crudActions from '@/libs/storeConstActions'
import { customActionGet } from '@core/utils/utils'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = () => 'products'
const suppliersEndpoint = () => 'customers'
const Actions = crudActions(endpoint)
const suppliers = (_, queryParams) => customActionGet(suppliersEndpoint(), queryParams, () => {}, '')

// TRANSFER ORDER ENDPOINTS
const getStockProduct = ({ commit }, { id, queryParams }) => customActionGet(`products/${id}`, queryParams, commit, 'GET_PRODUCT')
const getProduct = (ctx, queryParams) => axios.get('products/get-is-not-null-assets', { params: queryParams, paramsSerializer: params => stringify(params) })
const getDetails = (ctx, { id, type }) => axios.get(`orders/transfer/get-details?_id=${id}&type=${type}`)
// const getTransferOrder = (ctx, { transfer_id }) => axios.get(`orders/transfer/${transfer_id}`)
const createTransferOrder = (ctx, data) => {
  const { transferOrder } = data
  return axios.post('orders/transfer', transferOrder)
}
const updateTransferOrder = (ctx, { id, transferOrder }) => axios.patch(`orders/transfer/${id}`, transferOrder)
const forceTransferOrder = (ctx, data) => axios.post(`orders/transfer/force/${data.id}`, data.transferOrder)
const getTransferOrder = (ctx, data) => axios.get(`orders/transfer/${data.id}`)
const asSoonPossible = () => axios.get('orders/transfer/soon-possible')
const updateState = (ctx, data) => axios.patch('orders/transfer/update-state', data)
const release = (ctx, data) => axios.patch(`orders/transfer/release/${data.id}`, data.assets)

const getAssetList = (ctx, queryParams) => axios.get('inventories/list', { params: queryParams, paramsSerializer: params => stringify(params) })

export default {
  ...Actions,
  release,
  suppliers,
  getStockProduct,
  getProduct,
  getDetails,
  forceTransferOrder,
  getTransferOrder,
  asSoonPossible,
  createTransferOrder,
  updateTransferOrder,
  updateState,
  getAssetList,

}
