import mainStore from '@/store'
import purchaseModule from '@/store/main/orders/purchase-cr'
import mainConfig from '../../config'

// Get Order Statuses
const {
  ORDERS_STATUS_READY,
  ORDERS_STATUS_VOID,
} = mainConfig()

export default function config() {
  // Constants
  const MODULE_NAME = 'purchase-cr'
  const MODULE_NAME_CLONE = 'cloneData'

  const store = mainStore
  const model = purchaseModule

  const ORDERS_STATUS_READY_STATUS = ORDERS_STATUS_READY.status
  const ORDERS_STATUS_REJECTED = ORDERS_STATUS_VOID.status
  const ORDERS_STATUS_AS_DRAFT = 1
  const ORDERS_STATE_CLOSED_ORDER = 2

  const PAGE_TITLE = 'Purchase Order/Credit Notes List'
  const CREATE_PAGE_TITLE = 'New Direct Request for Quote'
  const CREATE_CREDIT_NOTE_PAGE_TITLE = 'Request for Credit Note'

  const RFQ_TITLE_PREFIX = 'RFQ'
  const RFQ_TITLE_SUFFIX_SENT = 'Sent'
  const RFQ_TITLE_SUFFIX_DRAFT = 'Draft'
  const RFQ_TITLE_SUFFIX_VOIDED = 'Voided'

  const RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_PREFIX = 'Quote'
  const RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_SUFFIX = 'from'

  const PURCHASE_ORDER_TITLE_PREFIX = 'Purchase Order'
  const PURCHASE_ORDER_TITLE_SUFFIX = 'for'

  const tableColumns = [
    {
      key: 'order_no',
      label: 'Order No.',
      thStyle: {
        width: '8%',
      },
      isSortable: true,
    },
    {
      key: 'state',
      label: 'State',
      thStyle: {
        width: '13%',
      },
      isSortable: true,
    },
    {
      key: 'order_date',
      label: 'Order Date',
      thStyle: {
        width: '10%',
      },
      isSortable: true,
    },
    {
      key: 'supplier',
      label: 'From',
      thStyle: {
        width: '20%',
      },
      isSortable: true,
    },
    {
      key: 'internal_order_reference',
      label: 'Reference',
      thStyle: {
        width: '27%',
      },
      isSortable: true,
    },
    {
      key: 'amount',
      label: 'Amount ',
      thStyle: {
        textAlign: 'right',
        width: '12%',
        paddingRight: '12px',
      },
      isSortable: true,
    },
  ]
  const quoteStatusBar = [
    {
      icon: 'LQuoteIconFill',
      title: 'Request for Quote',
      iconSize: '26',
    },
    {
      icon: 'LSignatureIconFill',
      title: 'Purchase Order',
      iconSize: '26',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Invoice',
      iconSize: '26',
    },
  ]
  const quoteStatusBarCreditNote = [
    {
      icon: 'LQuoteIconFill',
      title: 'Credit Note',
      iconSize: '26',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Paid',
      iconSize: '26',
    },
  ]
  const tableColumnsOtherCharges = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '58%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'price',
      label: 'Price',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'totals',
      label: 'Subtotal',
      thStyle: { width: '10%', textAlign: 'center' },
    },
  ]
  const tableColumnsOtherChargesOnIsNewDirectRequest = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '58%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
  ]

  const orderStatus = [
    {
      icon: 'LQuoteIconFill',
    },
    {
      icon: 'LSignatureIconFill',
    },
    {
      icon: 'LCreditCardIconFill',
    },
    {
      icon: 'LTruckIcon',
    },
    {
      icon: 'LWarehouseIcon',
    },
    {
      icon: 'LBoxSentIcon',
    },
  ]

  const orderPurchaseStatesRedirectToPages = [
    {
      state: 0,
      redirectPath: 'home-orders-purchase-cr-create',
      redirectPathOnStatusReadyStateZero: 'home-orders-purchase-rfq-for-supplier',
      redirectPathOnStatusSaveAsDraftStateZero: 'direct-request-for-quote',
    },
    {
      state: 1,
      redirectPath: 'home-orders-purchase-register-received-quote-from-supplier',
      redirectPathOnStatusRejectedStateOne: 'purchase-order-for-supplier',
    },
    {
      state: 2,
      redirectPath: 'purchase-order-for-supplier',
      redirectPathOnStatusReadyStateTwo: 'purchase-order-for-supplier',
    },
  ]

  return {
    store,
    model,
    PAGE_TITLE,
    orderStatus,
    MODULE_NAME,
    tableColumns,
    quoteStatusBar,
    RFQ_TITLE_PREFIX,
    MODULE_NAME_CLONE,
    CREATE_PAGE_TITLE,
    RFQ_TITLE_SUFFIX_SENT,
    RFQ_TITLE_SUFFIX_DRAFT,
    ORDERS_STATUS_REJECTED,
    ORDERS_STATUS_AS_DRAFT,
    RFQ_TITLE_SUFFIX_VOIDED,
    tableColumnsOtherCharges,
    quoteStatusBarCreditNote,
    ORDERS_STATE_CLOSED_ORDER,
    ORDERS_STATUS_READY_STATUS,
    PURCHASE_ORDER_TITLE_PREFIX,
    PURCHASE_ORDER_TITLE_SUFFIX,
    CREATE_CREDIT_NOTE_PAGE_TITLE,
    orderPurchaseStatesRedirectToPages,
    RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_PREFIX,
    RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_SUFFIX,
    tableColumnsOtherChargesOnIsNewDirectRequest,
  }
}
