import { reactive } from '@vue/composition-api'

export default {
  quote: {},
  quotes: [],
  quoteForm: {},
  stockItems: [],
  otherChargesItems: [],
  orderInformationForm: {},
  customerInformationForm: {
    attachments: [],
  },
  insuranceInformationForm: {},
  dispatchAndReturnForm: reactive({}),
  eventInformationForm: {},
  // orderItemsRentalTable: {
  //   products: [],
  // },
  orderItemsTable: {
    products: [],
  },
  orderItemsOtherChargesTable: {
    otherCharges: [],
  },
  orderItemsOtherChargesTableRef: null,
  totalsForm: {},
  onError: {},

  //  Received Quote From Supplier
  receiveWarehouseForm: {},
  receiveAttachments: [],

  //  Purchase Order  for SUPPLIER

  uploadInvoiceCollapseIsOpen: false,
  POInvoice: { },

  otherChargeError: null,
  //  Purchase Order Create
  credit: [],
  creditTotal: {
    items: 0,
    other_charges: 0,
    discounts: 0,
    taxes: 0,
    total: 0,
  },
  order: {},
  orderItemsSalesTable: {
    products: [],
  },
}
