<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Transfer Items') }}
      </h2>
      <span>
        <feather-icon
          icon="LWarningIconBlue"
          class="featherIcon"
          size="30"
        />
        {{ $t('To start the pick process, press pick or uncheck the items not to be picked.') }}</span>
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Order RO-') }}
      </h2>
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Permanent Transfer') }}
      </h2>
      <l-table-list-collector
        ref="lTableRef"
        :table-columns="tableColumns"
        :module-name="MODULE_NAME"
        :fetched-data="transferOrderAssets"
        :is-searchable.camel="false"
      >
        <template #head(action)>
          <div
            class="d-flex p-0"
          >
            <b-form-checkbox class="_custom_checkbox" />
          </div>
        </template>
        <template #cell(action)="{data: {item}}">
          <div
            class="d-flex pl-1"
          >
            <b-form-checkbox
              v-model="picket"
              class="_custom_checkbox"
              :value="item.id"
            />
            <!--  v-model="picket" -->
            <!--  :value="item.id" -->
          </div>
        </template>
        <template #cell(state)>
          <div
            class="d-flex pl-1"
          >
            <feather-icon
              icon="LUpPickIcon"
              class="featherIcon"
              size="30"
            />
          </div>
        </template>
        <template #cell(sku)="{data: {item}}">
          <div
            class="d-flex pl-1"
          >
            {{ item.sku }}
          </div>
        </template>
        <template #cell(name)="{data: {item}}">
          <div
            class="d-flex pl-1"
          >
            {{ item.name }}
          </div>
        </template>
        <template #cell(location)="{data: {item}}">
          <div
            class="d-flex pl-1"
          >
            {{ item.location }}
          </div>
        </template>
        <template #cell(asset_id)>
          <div
            class="d-flex p-0"
          >
            <feather-icon
              icon="LDangerIcon"
              class="lightIcon border-dotted featherIcon"
              size="30"
            />
            —
          </div>
        </template>
        <template #cell(piked)>
          <div class="d-flex p-0" />
        </template>
        <template #cell(inspected)="{data: {item}}">
          <div
            class="d-flex p-0"
          >
            <b-form-checkbox
              :checked="item.inspected"
            />
          </div>
        </template>
      </l-table-list-collector>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'TransferItems',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    LTableListCollector,
    BFormCheckbox,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    transferOrderAssets() {
      return this.$store.state[this.MODULE_NAME].transferOrder.product.assets
    },
    picket: {
      get(e) {
        console.log(e)
      },

      set(e) {
        console.log(e)
        // return true;
      },
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-checkbox .custom-control-label::before, .custom-radio .custom-control-label::after{
  width: 190px;
 }
</style>
