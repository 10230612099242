import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'

export default function () {
  const ORDER_INFORMATION_TITLE = 'Order Information'

  const orderInformationObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Supplier',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Dispatch Warehouse',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'required_by', '—')}`,
      label: 'Required by',
    },
    {
      key: 'dispatch_method.name',
      label: 'Dispatch Method',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'billing_contact.first_name', '—')} ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name', '')}`,
      label: 'Billing Contact',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'dispatch_contact.firstname', '—')} ${getValueFromGivenObjectByKey(data, 'dispatch_contact.lastname', '')}`,
      label: 'Dispatch Contact',
    },
    {
      key: 'billing_contact.phone_one',
      label: 'Billing Phone',
    },
    {
      key: 'dispatch_contact.phone',
      label: 'Dispatch Phone',
    },
    {
      key: 'billing_contact.email',
      label: 'Billing Email',
    },
    {
      key: 'dispatch_contact.email',
      label: 'Dispatch Email',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_address.street', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'billing_address.line2', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.city', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.state.code', '')}
      ${getValueFromGivenObjectByKey(data, 'billing_address.zip', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.country.three_letter', '')}
      `,
      label: 'Billing Address',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.street', '—')},
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.city', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.state.name', '')} 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.zip_code', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.country.two_letter', '')} 
      `,
      label: 'Dispatch Address',
    },

  ]

  const RFQ_SENT_BOTTOM_TITLE_ATTACHMENT = 'Attachments'
  const RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES = 'External Order Notes (visible to the supplier)'
  const RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES = 'Internal Order Notes (not visible to the supplier)'

  const orderInformationObjectForRenderBottom = [
    {
      key: 'internal_order_reference',
      label: RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES,
    },
    {
      key: 'external_order_notes',
      label: RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES,
    },
  ]
  const STOCK_ITEMS_TITLE = 'Stock Items'
  const StockItemTableColumns = [
    {
      key: 'action',
      label: '',
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%' },
    },
    {
      key: 'mpn',
      label: 'MPN',
      thStyle: { width: '8%' },
    },
    {
      key: 'supplier_sku',
      label: 'Supplier SKU',
      thStyle: { width: '12%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '30%' },
    },
    {
      key: 'amount',
      label: 'Units',
      thStyle: { width: '7%' },
    },
    {
      key: 'last_price',
      label: 'Last Price',
      thStyle: { width: '10%' },
    },
    {
      key: 'last_purchase_date',
      label: 'Last Purchase Date',
      thStyle: { width: '20%' },
    },
  ]

  const RFQ_SENT_ACTION_BTN_PRINT = {
    title: 'Print',
    action: '',
  }

  const RFQ_SENT_ACTION_CHANGE_RFQ = {
    title: 'Change RFQ',
    action: 'change_quote',
  }

  const RFQ_SENT_ACTION_SAVE_AS_DRAFT = {
    title: 'Save as Draft',
    action: '',
  }

  const RFQ_SENT_BY_EMAIL = {
    title: 'Send by Email',
    action: 'send',
    successMsg: 'Sent by Email successfully',
  }

  const RFQ_SENT_ACTION_VOID = {
    title: 'Void',
    action: 'void',
    successMsg: 'Voided successfully',
  }

  const RFQ_SENT_ACTION_SEND = {
    title: 'Send',
    action: 'send',
    successMsg: 'Sent successfully',
  }
  const RFQ_SENT_ACTION_BACK_RFQ = {
    title: 'Back RFQ',
    action: 'back_rfq',
    successMsg: 'RFQ Back successfully',
  }
  const RFQ_SENT_ACTION_SEND_AGAIN = {
    title: 'Send Again',
    action: 'send_again',
    successMsg: 'RFQ Sent again successfully',
  }
  const RFQ_SENT_ACTION_REJECT_QUOTE = {
    title: 'Reject Quote',
    action: 'reject_quote',
    successMsg: 'Quote reject successfully',
  }
  const RFQ_SENT_ACTION_QUOTE_RECEIVED = {
    title: 'Quote Received',
    action: 'quote_received',
    successMsg: 'Quote Received successfully',
  }
  const RFQ_SENT_ACTION_BACK_RFQ_DRAFT = {
    title: 'Back RFQ Draft',
    action: 'back_rfq_draft',
    successMsg: 'RFQ Draft Back successfully',
  }

  const uploadAttachmentTableCol = [
    {
      key: 'action',
      label: '',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '10%',
      },
    },
    {
      key: 'name',
      label: 'File Name',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '30%',
      },
    },
    {
      key: 'date',
      label: 'Date',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
    {
      key: 'type',
      label: 'Type',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
    {
      key: 'category',
      label: 'Category',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
  ]

  return {
    STOCK_ITEMS_TITLE,
    RFQ_SENT_BY_EMAIL,
    RFQ_SENT_ACTION_VOID,
    RFQ_SENT_ACTION_SEND,
    StockItemTableColumns,
    ORDER_INFORMATION_TITLE,
    uploadAttachmentTableCol,
    RFQ_SENT_ACTION_BACK_RFQ,
    RFQ_SENT_ACTION_BTN_PRINT,
    RFQ_SENT_ACTION_SEND_AGAIN,
    RFQ_SENT_ACTION_CHANGE_RFQ,
    RFQ_SENT_ACTION_REJECT_QUOTE,
    RFQ_SENT_ACTION_SAVE_AS_DRAFT,
    RFQ_SENT_ACTION_BACK_RFQ_DRAFT,
    RFQ_SENT_ACTION_QUOTE_RECEIVED,
    orderInformationObjectForRender,
    RFQ_SENT_BOTTOM_TITLE_ATTACHMENT,
    orderInformationObjectForRenderBottom,
    RFQ_SENT_BOTTOM_TITLE_EXTERNAL_ORDER_NOTES,
    RFQ_SENT_BOTTOM_TITLE_ATTACHMENT_INTERNAL_ORDER_NOTES,
  }
}
