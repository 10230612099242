export default {
  getTotalDataOfForms: state => ({
    ...state.orderInformationForm,
    ...state.customerInformationForm,
    ...state.insuranceInformationForm,
    ...state.dispatchAndReturnForm,
    ...state.eventInformationForm,
    // ...state.orderItemsRentalTable,
    ...state.orderItemsTable,
    ...state.orderItemsOtherChargesTable,
    ...state.totalsForm,
  }),
  getReceivedQuoteFromSupplierTotalDataOfForms: state => ({
    ...state.quote,
    ...state.receiveWarehouseForm,
    ...state.receiveAttachments,
    ...state.orderItemsOtherChargesTable,
  }),
}
