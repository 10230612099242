<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t('Fulfillment for Order TO-') }} {{ id }}
      </h3>
    </portal>
    <quote-status-bar
      :item="transferOrder"
      :quote-status-bar="quoteStatusBar"
      class="ml-1"
    />
    <div class="mt-1">
      <transfer-dispatch />
    </div>
    <div class="mt-1">
      <transfer-items />
    </div>
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <b-button
        v-if="true"
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
      >
        {{ $t('Back to List') }}
      </b-button>
      <div class="d-flex">
        <b-button
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        >
          <Printer>
            <div
              id="rfq"
              hidden
            >
              <RFQPrintTemplate />
            </div>
          </Printer>
          <feather-icon
            icon="LPrintIcon"
            style="margin-right: 10px"
            size="20"
          />
          <span>{{ $t('Print Pick List') }}</span>
        </b-button>
        <b-button
          variant="success"
          class="d-flex  justify-content-center ml-2"
          style="width: 170px; height: 40px;"
        >
          <feather-icon
            icon="LPickIcon"
            style="margin-right: 10px"
            size="20"
          />
          <span>{{ $t('Pick') }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { ValidationObserver } from 'vee-validate'
import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import TransferDispatch from '@/views/main/warehouse/view/pick/transfer/components/TransferDispatch.vue'
import TransferItems from '@/views/main/warehouse/view/pick/transfer/components/TransferItems.vue'
import { BButton } from 'bootstrap-vue'
import RFQPrintTemplate
from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/components/RFQPrintTemplate.vue'
import Printer from '@/views/components/Printer/Printer.vue'

export default {
  name: 'TransferForm',
  components: {
    Printer,
    RFQPrintTemplate,
    BButton,
    TransferItems,
    TransferDispatch,
    QuoteStatusBar,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    transferOrder() {
      return this.$store.state[this.MODULE_NAME].transferOrder
    },
  },
  setup() {
    const { transferFields, MODULE_NAME, quoteStatusBar } = config()
    return {
      transferFields,
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
