var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-wrapper"},[_c('h4',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.RFQ_SENT_BOTTOM_TITLE_ATTACHMENT)+" ")]),_c('div',[_c('b-table',{staticClass:"upload-attachment-table",attrs:{"items":_vm.attachments,"fields":_vm.uploadAttachmentTableCol,"show-empty":true,"empty-text":'None found'},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"8px"}},[_c('a',{on:{"click":function($event){return _vm.showAttachment(data.item)}}},[_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-eye-icon",staticStyle:{"padding":"5px"},attrs:{"icon":"LEyeIcon","size":"28"}})],1)])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name-cell",staticStyle:{"width":"170px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapDate(item.created_at))+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mime_type)+" ")]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attachment_category)+" ")]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }