var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white px-1 rounded-lg"},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Transfer Items'))+" ")]),_c('span',[_c('feather-icon',{staticClass:"featherIcon",attrs:{"icon":"LWarningIconBlue","size":"30"}}),_vm._v(" "+_vm._s(_vm.$t('To start the pick process, press pick or uncheck the items not to be picked.')))],1),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Order RO-'))+" ")]),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Permanent Transfer'))+" ")]),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"fetched-data":_vm.transferOrderAssets,"isSearchable":false},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex p-0"},[_c('b-form-checkbox',{staticClass:"_custom_checkbox"})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_c('b-form-checkbox',{staticClass:"_custom_checkbox",attrs:{"value":item.id},model:{value:(_vm.picket),callback:function ($$v) {_vm.picket=$$v},expression:"picket"}})],1)]}},{key:"cell(state)",fn:function(){return [_c('div',{staticClass:"d-flex pl-1"},[_c('feather-icon',{staticClass:"featherIcon",attrs:{"icon":"LUpPickIcon","size":"30"}})],1)]},proxy:true},{key:"cell(sku)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(location)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_vm._v(" "+_vm._s(item.location)+" ")])]}},{key:"cell(asset_id)",fn:function(){return [_c('div',{staticClass:"d-flex p-0"},[_c('feather-icon',{staticClass:"lightIcon border-dotted featherIcon",attrs:{"icon":"LDangerIcon","size":"30"}}),_vm._v(" — ")],1)]},proxy:true},{key:"cell(piked)",fn:function(){return [_c('div',{staticClass:"d-flex p-0"})]},proxy:true},{key:"cell(inspected)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-flex p-0"},[_c('b-form-checkbox',{attrs:{"checked":item.inspected}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }