<template>
  <div>
    <div class="mb-4">
      <img
        :src="ErboLogo"
        alt="aw"
      >
    </div>
    <h3
      class="text-center font-weight-bolder mb-4"
      style="color: #646464"
    >
      {{ $t(RFQ_TITLE_PREFIX) }}
      {{ getValueFromGivenObjectByKey(quote, 'id') }}
      {{ $t('for') }}
      {{ getValueFromGivenObjectByKey(quote, 'supplier.cust_name_dba') }}
      —
      {{ $t(quote.status === 1 ? RFQ_TITLE_SUFFIX_DRAFT : RFQ_TITLE_SUFFIX_SENT) }}
    </h3>
    <quote-status-bar
      :item="quote"
      class="mb-4"
    />
    <order-information :is-print="true" />
    <stock-items :is-print="true" />
    <order-information-bottom :is-print="true" />
  </div>
</template>
<script>
import config from '@/views/main/orders/view/purchase/config'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import ErboLogo from '@/assets/images/ico/erbo-logo.svg'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import OrderInformation from '../OrderInformation.vue'
import StockItems from '../StockItems.vue'
import OrderInformationBottom from '../OrderInformationBottom.vue'

export default {
  name: 'RFQPrintTemplate',
  components: {
    OrderInformation, QuoteStatusBar, StockItems, OrderInformationBottom,
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  setup() {
    const {
      RFQ_TITLE_PREFIX, RFQ_TITLE_SUFFIX_SENT, MODULE_NAME, PAGE_TITLE, RFQ_TITLE_SUFFIX_DRAFT,
    } = config()

    return {
      ErboLogo,
      PAGE_TITLE,
      MODULE_NAME,
      RFQ_TITLE_PREFIX,
      RFQ_TITLE_SUFFIX_SENT,
      RFQ_TITLE_SUFFIX_DRAFT,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
