export default function config() {
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const MODULE_NAME = 'pick-transfer-order'

  const tableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '5%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '30%' },
    },
    {
      key: 'location',
      label: 'Location',
      thStyle: { width: '15%' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
      thStyle: { width: '10%' },
    },
    {
      key: 'picked',
      label: 'Picked',
      thStyle: { width: '12%' },
    },
    {
      key: 'inspected',
      label: 'Inspected',
      thStyle: { width: '25%' },
    },
  ]
  const quoteStatusBar = [
    {
      icon: 'LPickIcon',
      title: 'Pick',
      iconSize: '26',
      id: 1,
    },
    {
      icon: 'LBoxIcon',
      title: 'Pack',
      iconSize: '26',
      id: 2,
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '26',
      id: 3,
    },
  ]
  return {
    tableColumns,
    MODULE_NAME,
    quoteStatusBar,
    ACCESS_ABILITY_SETTINGS_ORDERS,
  }
}
