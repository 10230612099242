import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = () => 'warehouse/transfer'
const Actions = crudActions(endpoint)
// eslint-disable-next-line no-unused-vars
const getTransferOrders = ({ commit }, id) => axios.get(`${endpoint()}/${id}/pick`)

// eslint-disable-next-line no-unused-vars
// const getTransferOrdersTable = ({ commit }, params) => new Promise((resolve, reject) => {
//   axios.get(`${endpoint()}/${params.id}/pick`, { params })
//     .then(response => {
//       const { data } = response.data
//       commit('LIST', data.product.assets)
//       resolve(response)
//     })
//     .catch(error => {
//       console.log(error.message)
//       reject(error)
//     })
// })
export default {
  ...Actions,
  // getTransferOrdersTable,
  getTransferOrders,
}
