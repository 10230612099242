<template>
  <div class="form-wrapper">
    <h3
      v-if="!isPrint"
      class="font-weight-bolder mb-1"
    >
      {{ $t(ORDER_INFORMATION_TITLE) }}
    </h3>
    <b-row>
      <b-col
        v-for="({key , label}, idx) in orderInformationObjectForRender"
        :key="idx"
        cols="6"
      >
        <b-row style="margin-bottom: 8px">
          <b-col cols="6">
            <b style="color: #646464">{{ label }}</b>
          </b-col>
          <b-col cols="6">
            <span style="color: #646464">{{ getValueFromGivenObjectByKey(quote, key) }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'OrderInformation',
  components: {
    BRow,
    BCol,
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  setup() {
    const {
      ORDER_INFORMATION_TITLE,
      orderInformationObjectForRender,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      ORDER_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      orderInformationObjectForRender,
    }
  },
}
</script>
