export default {
  GET(state, data) {
    state.order = { ...data }
  },
  LIST(state, data) {
    state.orders = data
  },
  SET_ORDERS_FORM(state, data) {
    state.orderForm = { ...data }
  },
  TRANSFER_ORDER_LIST(state, data) {
    state.transferOrderList = data
  },
  SET_ORDERS_PAGE_TITLE(state, title) {
    state.orderPageTitle = title
  },
  GET_PRODUCT(state, data) {
    state.product = { ...data }
  },
  SET_PERMANENT_TRANSFER_FORM_BY_INVENTORY_QTY(state, data) {
    state.inventoryPermanentTransferFormByInventoryQty = data
  },
  SET_TRANSFER_ORDER_SCHEDULED(state, data) {
    state.transferOrderScheduledInformation = data
  },
  SET_PERMANENT_TRANSFER_FORM_BY_ASSET_ID(state, data) {
    state.inventoryPermanentTransferFormByAsset = data
  },
  SET_TRANSFER_ORDER_QUOTE(state, data) {
    state.quoteTransferOrder = data
  },
}
